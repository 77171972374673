<template>
  <div id="funds_module">
    <div class="px-4 py-2 d-flex align-center">
      <div class="font-weight-bold primaryColor" id="funds_module_label">Funds</div>
      <v-progress-circular
            class="ml-2"
            indeterminate
            size="18"
            v-if="fundsOrHoldingsLoader"
            :width="2"
            color="blue"
          ></v-progress-circular>
    </div>
    <v-row class="ma-0 px-4">
      <v-slide-group v-model="infoType" class="pa-0" mandatory hide-arrows>
        <v-slide-item
          v-for="(item, i) in Items"
          :key="i"
          v-slot="{ active, toggle }"
        >
          <v-btn
            depressed
            :color="
              active ? 'activeColor white--text' : 'unActiveColor black--text'
            "
            height="26"
            min-width="50"
            class="fsize12 rounded-sm text-center text-capitalize"
            :value="item"
            @click="toggle"
            :id="`${isFunds ? isFunds :  item}_menu`"
          >
            {{ isFunds ? isFunds :  item }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-row>

    <v-divider class="mx-4 my-2"></v-divider>

    <div class="px-4 pb-4" v-if="!this.fundsOrHoldingsLoader">
      <v-card >
        <v-layout row wrap align-center justify-center class="mx-4 mt-4" v-if="!isFunds">
          <v-flex
            xs12
            sm12
            md6
            lg6
            xl6
            align-center
            justify-center
            text-center
            class="my-2"
            
          >
                   <div class="text-center">
              <label class="fsize12 secondColor" id="fund_avail_mar_label">Available Margin</label>
              <div>
                <label class="fsize20 primaryColor font-weight-bold">&#8377;</label>&nbsp;<span
                  class="fsize20 primaryColor font-weight-bold" id="fund_avail_mar_val"
                  >{{this.fundsLimit.net ? ruppesFormat(formatNum(this.fundsLimit.net)) : 'NA'}} 
                </span>
              </div>
            </div>

            <div class="row ma-0 pt-8">
              <div class="col-6 pa-0 text-center">
                <label class="fsize12 secondColor" id="fund_open_bal_label">Opening Balance</label>
                <div>
                  <label class="fsize20 primaryColor">&#8377;</label>&nbsp;<span
                    class="fsize20 primaryColor" id="fund_open_bal_val"
                    >{{this.fundsLimit.cashmarginavailable ? ruppesFormat(formatNum(this.fundsLimit.cashmarginavailable)) : 'NA'}}</span
                  >
                </div>
              </div>

              <div class="col-6 pa-0 text-center">
                <label class="fsize12 secondColor text-capitalize" id="fund_margin_used_label">Margin Used</label>
                <div>
                  <label class="fsize20 primaryColor">&#8377;</label>&nbsp;<span
                    class="fsize20 primaryColor" id="fund_margin_used_val"
                    >{{
                     this.fundsLimit.subtotal ?  ruppesFormat(formatNum(this.fundsLimit.subtotal)) : 'NA'
                    }}</span
                  >
                </div>
              </div>
            </div>

            <div class="row ma-0 pt-8 d-flex justify-center">
               <v-btn id="fund_deposit_btn"
                  depressed
                  color="#4184f3"
                  class="rounded mr-3 mt-3 text-capitalize white--text"
                  width="120"
                  height="40"
                  :loading="clientLoader"
                  :disabled="clientLoader"
                 @click="resetDepositField();callDeposit()"
                  >Deposit</v-btn
                >
                <v-btn id="fund_withdraw_btn"
                  depressed
                  color="#26a69a"
                  class=" white--text rounded ma-0 mr-3 mt-3 text-capitalize"
                  width="120"
                  height="40"
                  :loading="withdrawLoader"
                  :disabled="withdrawLoader"
                  @click="$store.dispatch('funds/withDrawMethod')"
                  >Withdraw</v-btn
                >
            </div>
            <div v-if="isValidToShowLean">
              <v-btn id="lean_btn" depressed color="#4184f3" class="rounded mr-3 mt-3 text-capitalize white--text" width="120" height="40" 
                @click="goToLien()" >Lien</v-btn>
              <v-btn id="unlean_btn" depressed color="#4184f3" class="rounded mr-3 mt-3 text-capitalize white--text" width="120" height="40" 
                @click="goToUnLien()" >Un Lien</v-btn>
            </div>
          </v-flex>
                    <v-divider v-if="$store.state.windowWidth > 1263" vertical class="my-4"></v-divider>
          <v-flex xs12 sm12 md12 lg6 xl6 align-center justify-center  class="mt-2 mb-4">
          <div class="px-4 py-2 fsize14 l-height-24" id="fund_payin">
              <span class="secondaryColor" id="fund_payin_label">Payin</span> <span id="fund_payin_val" class="float-right primaryColor">{{this.fundsLimit.rmsPayInAmnt ?   parseFloat(this.fundsLimit.rmsPayInAmnt).toFixed(2) : 'NA' }}</span>
            </div>
            <div class="px-4 py-2 fsize14 l-height-24" id="fund_payout">
              <span class="secondaryColor" id="fund_payout_label">Payout</span> <span id="fund_payout_val" class="float-right primaryColor">{{this.fundsLimit.payoutamount ?  parseFloat(this.fundsLimit.payoutamount).toFixed(2) : 'NA' }}</span>
            </div>
            <div class="px-4 py-2 fsize14 l-height-24" id="fund_adhoc_mar">
              <span class="secondaryColor" id="fund_adhoc_mar_label">Adhoc Margin</span> <span id="fund_adhoc_mar_val" class="float-right primaryColor">{{this.fundsLimit.adhocMargin ?  parseFloat(this.fundsLimit.varmargin).toFixed(2) : 'NA' }}</span>
            </div>
            <div class="px-4 py-2 fsize14 l-height-24" id="fund_elm">
              <span class="secondaryColor" id="fund_elm_label">ELM</span> <span id="fund_elm_val" class="float-right primaryColor">{{ this.fundsLimit.elm ?  parseFloat(this.fundsLimit.elm).toFixed(2) : 'NA'  }}</span>
            </div>
            <div class="px-4 py-2 fsize14 l-height-24" id="fund_span">
              <span class="secondaryColor" id="fund_span_label">SPAN</span> <span id="fund_span_val" class="float-right primaryColor">{{ this.fundsLimit.spanmargin  ? parseFloat(this.fundsLimit.spanmargin).toFixed(2) : 'NA' }}</span>
            </div>
            <div class="px-4 py-2 fsize14 l-height-24" id="fund_exposure">
              <span class="secondaryColor" id="fund_exposure_label">Exposure</span> <span id="fund_exposure_val" class="float-right primaryColor">{{ this.fundsLimit.exposuremargin  ? parseFloat(this.fundsLimit.exposuremargin).toFixed(2) : 'NA' }}</span>
            </div>
            <div class="px-4 py-2 fsize14 l-height-24" id="fund_opt_pre">
              <span class="secondaryColor" id="fund_opt_pre_label">Options premium</span> <span id="fund_opt_pre_val" class="float-right primaryColor">{{ this.fundsLimit.premiumPrsnt  ? parseFloat(this.fundsLimit.premiumPrsnt).toFixed(2) : 'NA'  }}</span>
            </div>
            <div class="px-4 py-2 fsize14 l-height-24" id="fund_collat_valuat">
              <span class="secondaryColor" id="fund_collat_valuat_label">Collateral Valuation</span> <span id="fund_collat_valuat_val" class="float-right primaryColor">{{ this.fundsLimit.collateralvalue  ? parseFloat(this.fundsLimit.collateralvalue).toFixed(2) : 'NA'  }}</span>
            </div>
            <div class="px-4 py-2 fsize14 l-height-24" id="fund_direct_collat_valuat">
              <span class="secondaryColor" id="fund_direct_collat_valuat_label">Directcollateral Valuation</span> <span id="fund_direct_collat_valuat_val" class="float-right primaryColor">{{ this.fundsLimit.collateralvalue  ? parseFloat(this.fundsLimit.directcollateralvalue).toFixed(2) : 'NA'  }}</span>
            </div>
            <div class="px-4 py-2 fsize14 l-height-24" id="fund_booked_pnl">
              <span class="secondaryColor" id="fund_booked_pnl_label">Booked PNL</span> <span id="fund_booked_pnl_val" class="float-right primaryColor">{{ this.fundsLimit.realizedMtomPrsnt  ? parseFloat(this.fundsLimit.realizedMtomPrsnt).toFixed(2) : 'NA'  }}</span>
            </div>
            <div class="px-4 py-2 fsize14 l-height-24" id="fund_unbooked_pnl">
              <span class="secondaryColor" id="fund_unbooked_pnl_label">Unbooked PNL</span> <span id="fund_unbooked_pnl_val" class="float-right primaryColor">{{ this.fundsLimit.unrealizedMtomPrsnt  ? parseFloat(this.fundsLimit.unrealizedMtomPrsnt).toFixed(2) : 'NA'  }}</span>
            </div>
             <div class="px-4 py-2 fsize14 l-height-24" id="fund_national_cash">
              <span class="secondaryColor" id="fund_national_cash_label">National Cash</span> <span id="fund_national_cash_val" class="float-right primaryColor">{{ this.fundsLimit.notionalCash  ? parseFloat(this.fundsLimit.notionalCash).toFixed(2) : 'NA'  }}</span>
            </div>
          </v-flex>
        </v-layout>
         <div v-if="isFunds == 'Deposit'" class="pa-4">
          <v-layout row wrap justify-center class="pl-4 pr-4 mt-2">
            <v-flex xs12 sm12 md8 lg4 xl4 align-center justify-center>
              <span class="w-100 fsize12" id="fund_amt_label">Amount </span>
              <div class="border-input rounded">
                <span class="rupee-symbol">₹</span>
                <input id="fund_amt_inp"
                  type="text"
                  placeholder="Enter amount (min 50)"
                  v-model.number="amount"
                  class="
                    h-40
                    outline-none
                    pl-9
                    pr-4
                    py-2
                    border-none
                    fsize14
                    primaryColor
                    w-100
                  "
                  @keypress="keyPressNumeric($event);amountErrorMessage = ''"
                  autocomplete="off"
                />
              </div>
              <div
                class="error-msg ma-0 pl-3" v-if="amountErrorMessage"
                style="margin-bottom: 2px !important" id="fund_amt_err_msg"
              >
                <span  class="mb-2">{{
                  amountErrorMessage
                }}</span>
              </div>
              <div id="add_to_fund_label" class="d-flex flex-row w-100 my-2 fsize12">
                <v-btn
                :id="`${item.name}/button_fund`"
                  v-for="(item, id) in addFunds" :key="id"
                  @click=" amount = amount ? parseFloat(amount) + parseFloat(item.value) : parseFloat(item.value)"
                  depressed
                  outlined
                  height="20"
                  width="50"
                 
                  class="text-capitalize  d-flex mr-3  
                        border-0075e1
                        rounded-sm
                        color-0075e1
                       "
                  >{{ item.name }}</v-btn
                >
              </div>
             

              <span class="w-100 fsize12 mt-1" v-if="payment == 'UPI'" id="fund_vpa_label"
                >Virtual payment address (UPI ID)</span
              >
              <input id="fund_vpa_inp"
               v-if="payment == 'UPI'"
                type="text"
                placeholder="Eg: username@upi"
                v-model="bankDetails.upiId"
                class="
                  h-40
                  w-100
                  rounded
                  px-4
                  py-2
                  border-input
                  fsize14
                  mt-0
                  primaryColor
                "
                @keypress="upiErrorMessage= ''"
                autocomplete="off"
              />
              <div
               v-if="payment == 'UPI'"
                class="error-msg ma-0 pl-3"
                style="margin-bottom: 2px !important" id="fund_vpa_err_msg"
              >
                <span v-if="upiErrorMessage">{{ upiErrorMessage }}</span>
              </div>
              <div class="w-100 fsize12 mt-1" id="fund_select_acc_label">Select Account</div>
              <v-autocomplete id="fund_select_acc_val"
                v-model="selectAccount"
                :items="bankDetails.bankDetails"
                outlined
                dense
                height="40"
                single-line
                label="Select Account"
                @change="accountErrorMessage = ''"
                class="funds fsize12"
                item-text="showValue"
                item-value="showValue"
                :error-messages="accountErrorMessage"
              ></v-autocomplete>
              <div class="w-100 fsize12 mt-1 mb-2" id="fund_payment_mode_label">Payment mode</div>
              <v-radio-group v-model="payment" row class="ma-0 pa-0 payment" id="fund_payment_mode_radio_group">
                      <span
                        @click="payment = 'UPI'"
                        class="
                          fsize12
                          black--text
                          d-flex
                          align-center
                          mr-2
                          cursor
                        "
                      >
                        <customIcon
                          style="height: 16px"
                          class="mr-2"
                          :name="
                            payment == 'UPI' ? 'radio-check' : 'radio-blank'
                          "
                          :width="'18'"
                          :height="'18'"
                          :color="'#46C212'"
                        />
                        UPI
                      </span>
                      <span
                        @click="payment = 'Net'"
                        class="fsize12 black--text d-flex align-center cursor"
                      >
                        <customIcon
                          style="height: 16px"
                          class="mr-2"
                          :name="
                            payment == 'Net' ? 'radio-check' : 'radio-blank'
                          "
                          :width="'18'"
                          :height="'18'"
                          :color="'#46C212'"
                        />Net Banking
                      </span>
              </v-radio-group>

              <div class="float-right px-0 mb-6">
                <v-btn id="fund_deposit_confirm_btn"
                  :color="$store.state.buttonThemeColor"
                  depressed
                  class="fsize14 text-capitalize white--text mr-2 mt-2"
                  width="120px"
                  height="40px"
                  :loading="depositLoader"
                  :disabled="depositLoader"
                  @click="createPayment()"
                  >Continue</v-btn
                >

                <v-btn id="fund_deposit_cancel_btn"
                  depressed
                  outlined
                  class="fb-btn black--text text-capitalize mt-2"
                  @click="isFunds = null"
                  width="120px"
                  height="40px"
                  >Cancel</v-btn
                >
              </div>
            </v-flex>
            <v-flex xs12 sm12  lg2 xl2 class="pa-0 ma-0"></v-flex>
             <v-flex xs12 sm12 md4 lg6 xl6 class="py-0 pr-4 pl-4 pb-4">
            </v-flex> 
          </v-layout>
        </div>
        <lean v-else-if="isFunds == 'lien'"/>
        <unLean v-else-if="isFunds == 'unlien'"/>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import commonFunc from "../mixins/commonFunctions";
import customIcon from "../components/customIcon.vue"
import service from "../store/Services/httpservice"
import errorhanbling from "../store/Services/errorHandling"
import lean from "./fundsSubPages/lean.vue"
import unlean from "./fundsSubPages/unlean.vue"
export default {
  mixins: [commonFunc],

  data() {
    return {
      Items: ["Consolidated Margin"],
      infoType: "Consolidated Margin",
       segmentErrorMessage: '',
       amount: '',
       amountErrorMessage: '',
       payment: "UPI",
       addFunds: [
        { name: "+500", value: 500 },
        { name: "+1000", value: 1000 },
        { name: "+2000", value: 2000 },
        { name: "+5000", value: 5000 },
      ],
       upiErrorMessage: '',
       accountErrorMessage: '',
      razerPay_Id : "rzp_live_1GOg9YBWUQRIJX"
    };
  },
  components:{
    customIcon, lean, unlean
  },
  computed: {
    ...mapState("funds", ["fundsLimit", "withdrawLoader", "isFunds", "bankDetails"]),
    ...mapState(['fundsOrHoldingsLoader']),
    ...mapGetters("authentication", ["getUserSessionDto"]),

    isValidToShowLean() {
      return this.getUserSessionDto && this.getUserSessionDto.branch_id ? this.getUserSessionDto.branch_id.toUpperCase() === 'Z91' : false
    },

    depositLoader: {
      get: function(){
        return this.$store.state.funds.depositLoader
      },
      set: function(value){
        this.$store.commit("funds/setDepositLoader", value)
      }
    },
     isFunds: {
      get: function(){
        return this.$store.state.funds.isFunds
      },
      set: function(value){
        this.$store.commit('funds/setIsFunds' , value)
      }
    },
    clientLoader: {
      get: function(){
        return this.$store.state.funds.clientLoader
      },
      set: function(value){
        this.$store.commit("funds/setClientLoader", value)
      }
    },
    selectAccount: {
      get: function(){
        return this.$store.state.funds.selectAccount
      },
      set: function(value){
        this.$store.commit("funds/setAccount", value)
    }
    }
  },
  async created() {
    await this.$store.dispatch("funds/getFundsLimits");
  },
  methods:{
    keyPressNumeric(event) {
      if ( event.keyCode != 13 ) {
        var inp = String.fromCharCode(event.keyCode);
        if (/[0-9.]/.test(inp)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    },
     resetDepositField() {
       this.selectAccount = this.amountErrorMessage = this.segmentErrorMessage = this.upiErrorMessage =  this.accountErrorMessage = ""
       this.amount = ''
       this.payment = 'UPI'
    },
     async  createPayment() {
    
      if ((this.amount && this.amount >= 50 && this.selectAccount && this.bankDetails.upiId && this.payment == 'UPI') || (this.amount && this.amount >= 50 && this.selectAccount && this.payment == 'Net')) {
        this.depositLoader = true;
        this.accountErrorMessage =
          this.segmentErrorMessage =  this.amountErrorMessage = this.upiErrorMessage = "";

        
        if(!this.upiErrorMessage){
          this.depositLoader = true;
          var slectedItem = [];

         this.bankDetails.bankDetails.filter((el) => {
        this.selectAccount === el.showValue ? slectedItem.push(el) : "";
        });
        let jsonObj = {
          accNum: slectedItem[0]['bankAccNo'],
          amt: this.amount ,
          payMethod: this.payment,
          device: "WEB",
          accName: this.bankDetails.userName,
          ifscCode: slectedItem[0]["ifscCode"]
        };
    await  service
          .makePayment(jsonObj)
         .then(
           (resp) => {
          if (resp.status === 200 && resp.data.stat == "Ok") {
                 this.checkRazerpay(resp.data.result.orderId, slectedItem, resp.data.result.receiptId);
             } else {
                errorhanbling.toaster(resp.data.message, "danger", "", 3000);
               }
            },
            (err) => {
              errorhanbling.errLog(err);
            }
           )
          .finally(() => {
            this.depositLoader = false;
          });
        }
        
      } else {
        !this.amount
          ? (this.amountErrorMessage = "Please Enter Your Amount")
          : this.amount < 50 ?  (this.amountErrorMessage = "Amount should be above 50 or 50")  : (this.amountErrorMessage = "");
        
        !this.selectAccount
          ? (this.accountErrorMessage = "Please Select Your Account")
          : (this.accountErrorMessage = "");
        !this.bankDetails.upiId
          ? (this.upiErrorMessage = "Please Enter Your Valid UPI ID")
          : (this.upiErrorMessage = "");
      }
    },

     async  checkRazerpay(order_id, slectedItem, recipt_Id) {
    
      this.depositLoader = true;

      var razorpay = new Razorpay({
              key: this.razerPay_Id,
            });
            var tempAmount = this.amount
       var data = {
              amount: this.amount * 100,
              currency: "INR",
              email: this.bankDetails.email,
              contact: this.bankDetails.phone,
              order_id: order_id,
              method: this.payment == "UPI" ? "upi" : "netbanking",
            };
            
        if( this.payment == "UPI"){
           data['upi'] = {
            vpa: this.bankDetails.upiId,
              flow: 'collect'
          }
        }else{
          data["bank"] = slectedItem[0]['bankCd']
        }
        razorpay.createPayment(data)

       
razorpay.on('payment.success', function(resp) {
  errorhanbling.passData(tempAmount, recipt_Id, resp)
    }); // will pass payment ID, order ID, and Razorpay signature to success handler.

  razorpay.on('payment.error', function(resp){
    errorhanbling.toaster(resp.error.reason,"danger",resp.error.description, 4000)}); // will pass error object to error handler
      this.depositLoader = false;
    },
    async callDeposit(){
      this.clientLoader = true
      await this.$store.dispatch("funds/getPaymentBankDetails")
    },

    async goToLien() {
      this.$store.commit('funds/setIsFunds' , 'lien')
    },

    async goToUnLien() {
      this.$store.commit('funds/setIsFunds' , 'unlien')
    }
  },
  watch:{
     amount: function (val) {
      if (val) {
        val < 50
          ? (this.amountErrorMessage = "Amount should be above 50 or 50")
          : (this.amountErrorMessage = "");
      }
    },
  }
};
</script>