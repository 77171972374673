<template>
    <div class="px-5 py-5">
        <div class="mb-4 h-72" :class="$store.state.windowWidth > 374 ? ' w-296' : 'w-100'">
            <div>
                <label class="fsize14 primaryColor pl-2" id="lean_name">Name</label>
            </div>
            <input type="text" readonly placeholder="" class=" h-40 rounded px-4 py-2 border-input fsize14 primaryColor" id="lean_name_val" :class="$store.state.windowWidth > 374 ? ' w-296' : 'w-100'"  autocomplete="off" />
        </div>
        <div class="mb-4 h-72" :class="$store.state.windowWidth > 374 ? ' w-296' : 'w-100'">
            <div>
                <label class="fsize14 primaryColor pl-2" id="lean_acno">Account Number</label>
            </div>
            <input type="text" readonly placeholder="" class=" h-40 rounded px-4 py-2 border-input fsize14 primaryColor" id="lean_acno_val" :class="$store.state.windowWidth > 374 ? ' w-296' : 'w-100'"  autocomplete="off" />
        </div>
        <div class="mb-4 h-72" :class="$store.state.windowWidth > 374 ? ' w-296' : 'w-100'">
            <div>
                <label class="fsize14 primaryColor pl-2" id="lean_dot">Date of Trade</label>
            </div>
            <input type="text" readonly placeholder="" class=" h-40 rounded px-4 py-2 border-input fsize14 primaryColor" id="lean_dot_val" :class="$store.state.windowWidth > 374 ? ' w-296' : 'w-100'"  autocomplete="off" />
        </div>
        <div class="mb-4 h-72" :class="$store.state.windowWidth > 374 ? ' w-296' : 'w-100'">
            <div>
                <label class="fsize14 primaryColor pl-2" id="lean_lienid">Lien Id</label>
            </div>
            <input type="text" readonly placeholder="" class=" h-40 rounded px-4 py-2 border-input fsize14 primaryColor" id="lean_lienid_val" :class="$store.state.windowWidth > 374 ? ' w-296' : 'w-100'"  autocomplete="off" />
        </div>
        <div class="mb-4 h-72" :class="$store.state.windowWidth > 374 ? ' w-296' : 'w-100'">
            <div>
                <label class="fsize14 primaryColor pl-2" id="lean_clientcode">Client Code</label>
            </div>
            <input type="text" readonly placeholder="" class=" h-40 rounded px-4 py-2 border-input fsize14 primaryColor" id="lean_clientcode_val" :class="$store.state.windowWidth > 374 ? ' w-296' : 'w-100'"  autocomplete="off" />
        </div>
        <div class="mb-4 h-72" :class="$store.state.windowWidth > 374 ? ' w-296' : 'w-100'">
            <div>
                <label class="fsize14 primaryColor pl-2" id="lean_refno">Reference Number</label>
            </div>
            <input type="text" readonly placeholder="" class=" h-40 rounded px-4 py-2 border-input fsize14 primaryColor" id="lean_refno_val" :class="$store.state.windowWidth > 374 ? ' w-296' : 'w-100'"  autocomplete="off" />
        </div>
        <div class="mb-4 h-72" :class="$store.state.windowWidth > 374 ? ' w-296' : 'w-100'">
            <div>
                <label class="fsize14 primaryColor pl-2" id="lean_reqtype">Request Type</label>
            </div>
            <input type="text" placeholder="" class=" h-40 rounded px-4 py-2 border-input fsize14 primaryColor" id="lean_reqtype_val" :class="$store.state.windowWidth > 374 ? ' w-296' : 'w-100'"  autocomplete="off" />
        </div>
        <div class="mb-4 h-72" :class="$store.state.windowWidth > 374 ? ' w-296' : 'w-100'">
            <div>
                <label class="fsize14 primaryColor pl-2" id="lean_amount">Enter Amount</label>
            </div>
            <input type="text" placeholder="" class=" h-40 rounded px-4 py-2 border-input fsize14 primaryColor" id="lean_amount_val" :class="$store.state.windowWidth > 374 ? ' w-296' : 'w-100'"  autocomplete="off" />
        </div>
        <div>
            <v-btn id="lean_proceed_btn" :color="$store.state.buttonThemeColor" depressed class="fsize14 text-capitalize white--text mr-2 mt-2" width="120px" height="40px"  @click="proceed()" >Proceed</v-btn>
            <v-btn id="lean_cancel_btn" depressed outlined class="fb-btn black--text text-capitalize mt-2" @click="cancel()" width="120px" height="40px" >Cancel</v-btn>
        </div>
    </div>
</template>


<script>
export default {
    methods: {
        proceed() {

        },
        cancel() {
            this.$store.commit('funds/setIsFunds' , null)
        }
    },
}
</script>